$govuk-assets-path: '~govuk-frontend/govuk/assets/';
@import '~govuk-frontend/govuk/all';

:root {
  --amplify-primary-color: black;
  --amplify-primary-tint: black;
  --amplify-primary-shade: black;
}

// Support buttons as well as links
button.govuk-back-link,
button.govuk-link {
  appearance: unset;
  background: none;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  @extend .govuk-link--no-visited-state:link;

  &:disabled {
    text-decoration: unset;
    cursor: default;
    color: govuk-colour('black');
  }
}

// Allow grid within accordion section header
.govuk-accordion__section-button {
  width: 100%;
}

// .govuk-summary-list--no-border for tables
.govuk-table--no-border
  > .govuk-table__body
  > .govuk-table__row
  > .govuk-table__cell {
  border: none;
}

.govuk-table--small
  > .govuk-table__body
  > .govuk-table__row
  > .govuk-table__cell {
  // half cell padding
  padding: govuk-spacing(1) govuk-spacing(2) govuk-spacing(1) govuk-spacing(0);
}

.govuk-table--vertical-align-middle
  > .govuk-table__body
  > .govuk-table__row
  > .govuk-table__cell {
  vertical-align: middle;

  > :last-child {
    margin-bottom: govuk-spacing(0);
  }
}

.govuk-table--second-column-center
  > .govuk-table__body
  > .govuk-table__row
  > .govuk-table__cell:nth-child(2) {
  text-align: center;
  padding-right: govuk-spacing(0);
}

// Center progress bar vertically
.govuk-accordion__section-button > progress {
  transform: translateY(4px);
}

.govuk-accordion__controls {
  margin-bottom: govuk-spacing(1);
}

.js-enabled .govuk-accordion__section-content {
  padding-top: govuk-spacing(0);
  padding-bottom: govuk-spacing(6);
}

// No table row line for table containing one row
.govuk-table__body:first-child
  > .govuk-table__row:first-child:last-child
  > .govuk-table__cell {
  border: none;
}

// Remove padding around table in section heading which provides its own padding
.govuk-accordion__section-heading {
  .govuk-table {
    margin-bottom: 0;
  }

  .govuk-table__cell {
    padding-top: 0;
    padding-bottom: 0;

    // The entire section heading is a button for expanding the accordion section,
    // links within are not functional.
    a {
      color: unset;
      text-decoration: unset;
      // Unnecessary but semantically correct
      pointer-events: none;
    }
  }
}

// If summary list has no actions, don't pad the value column
.govuk-summary-list__value:last-child {
  padding-right: 0;
}

// Fade the radio hint like the label when the radio is disabled
.govuk-radios__input:disabled ~ .govuk-hint {
  opacity: 0.5;
}

// Ensure progress bar does not wrap siblings on mobile
progress {
  max-width: 60%;
}

// In return status accordion, only show more details in header if not expanded
[id*='commissionsCategories']
  .govuk-accordion__section-button[aria-expanded='true']
  > :not(:first-child):not(:last-child) {
  display: none;
}

// Show element when accordion expanded
.accordion-expanded-shown {
  display: none;

  .govuk-accordion__section--expanded & {
    display: unset;

    &.govuk-table__cell {
      display: table-cell;
    }
  }
}

// Hide element when accordion expanded
.govuk-accordion__section--expanded .accordion-expanded-hidden {
  display: none;
}

// Apparently …-one-quarter is actually …-one-quarter-from-desktop
// One quarter should just be one quarter!
.govuk-\!-width-one-quarter {
  width: 25% !important;
}

.text-center {
  text-align: center;
}

// Show ellipsis on end of first line
.text-ellipsis {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
}

// Show ellipsis on end of second line
.text-ellipsis-2 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-all;
  text-align: left;
}

.text-ellipsis-2-not-hover {
  @extend .text-ellipsis-2;

  &:hover {
    -webkit-line-clamp: unset;
  }
}

.flex-row {
  display: flex;
  justify-content: space-between;

  @include govuk-media-query($until: desktop) {
    flex-wrap: wrap;
  }

  > * {
    margin-right: govuk-spacing(1);
  }
}

.nav-float-right nav {
  display: inline-block;
  float: right;
}

.summary-flexible {
  table-layout: auto;

  > .govuk-summary-list__row {
    > .govuk-summary-list__key,
    > .govuk-summary-list__value {
      width: unset;
    }
  }
}

.monospace {
  font-family: monospace;

  &.govuk-heading-m {
    font-size: 1.25em;
  }
}

details[open] + .after-details-open-hide {
  display: none;
}

.details-closed-inline:not([open]) {
  display: inline-block;
  margin-right: govuk-spacing(4);
}

details[disabled] {
  opacity: 0.5;
  pointer-events: none;
}

@include govuk-media-query($from: mobile) {
  .trend-cell {
    width: 120px;
    line-height: 1;
  }
}

.metricsTable {
  display: 'inline-block';
}

.metricsTable > * {
  padding-right: 5px;
}
